import { useDispatch } from 'react-redux';
import { addParticipant, removeParticipant } from 'redux/reducer';
import { IncomingParticipant } from 'types';

type Dispatch = ReturnType<typeof useDispatch>;
class CommunicationClient {
  roomToken: string;
  localParticipantId: string;
  dispatch: Dispatch;

  constructor(roomToken: string, localParticipantId: string, dispatch: Dispatch) {
    this.roomToken = roomToken;
    this.localParticipantId = localParticipantId;
    this.dispatch = dispatch;
  }

  addIncomingParticipant(partcipant: IncomingParticipant) {
    this.dispatch(addParticipant(partcipant));
  }
  removeIncomingParticipant(...ids: string[]) {
    this.dispatch(removeParticipant(ids));
  }
}

export default CommunicationClient;
