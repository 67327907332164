import React from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles, styled, createStyles } from '@material-ui/core/styles';
import VideocamIcon from '@material-ui/icons/Videocam';
import Button from '@material-ui/core/Button';
import { useTranslation, Trans } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { ModalPropType } from './index';

const DesktopContainer = styled('div')({
  padding: '12px',
  backgroundColor: '#FFF',
  width: '550px',
  height: 'fit-content',
  position: 'relative',
  borderRadius: '12px',
  outline: 0,
  color: '#484848',
});

const FlexContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
});

const FlexColumnContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
});

const ButtonGroupContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '20px',
  fontSize: '16px',
});

const Header = styled('div')({
  marginBottom: '20px',
  fontWeight: 800,
});

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2000,
    },
    close: {
      position: 'absolute',
      right: '12px',
      top: '0px',
    },
    decline: {
      marginRight: '12px',
      color: '#5c8df3',
    },
    accept: {
      backgroundColor: '#5c8df3',
    },
    hint: {
      color: '#999',
      lineHeight: '40px',
    },
  })
);

const DesktopModal = ({ imMessageInfo, approveJoinRequest, rejectJoinRequest }: ModalPropType) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Modal
      open={!isEmpty(imMessageInfo)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <DesktopContainer>
        <Trans
          i18nKey="call.video_call_join_request"
          components={{ b: <Header /> }}
          defaults="<b>Video call join request</b>"
        />
        <FlexContainer>
          <FlexColumnContainer>
            <Trans
              i18nKey="call.request_to_join"
              values={{ name: imMessageInfo.name }}
              components={{ b: <div /> }}
              defaults="<b>%{name} request to join video chat room</b>"
            />
            <div className={classes.hint}>{t('call.join_in_soon')}</div>
          </FlexColumnContainer>
        </FlexContainer>
        <ButtonGroupContainer>
          <Button color="primary" onClick={rejectJoinRequest} className={classes.decline}>
            {t('call.decline', 'Decline')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.accept}
            startIcon={<VideocamIcon />}
            size="small"
            onClick={approveJoinRequest}
          >
            {t('call.approve', 'Approve')}
          </Button>
        </ButtonGroupContainer>
      </DesktopContainer>
    </Modal>
  );
};

export default DesktopModal;
