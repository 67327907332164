import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from './resources';

const defaultLang = 'en';

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLang,
  fallbackLng: {
    'zh-Hant': ['zh-Hans', 'zh', 'en'],
    'zh-Hans': ['zh-Hant', 'zh', 'en'],
    default: [defaultLang],
  },
  interpolation: {
    escapeValue: false,
    prefix: '%{',
    suffix: '}',
  },
});

export default i18n;
