/// <reference path="./RongIMLib-2.5.7.d.ts" />
import { Callback, IncomingParticipant, URLParameterType } from '../../../types';
import { isMobile } from '../../../utils';
import Video, { ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { useCallback, useEffect, useRef, useState } from 'react';
import CommunicationClient from './CommunicationClient';
import { useDispatch } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { RongCloudCommClient } from './RongCloudCommClient';
import TwilioCommClient from './TwilioCommClient';

interface CommClientExtenders extends CommunicationClient {
  joinRequestHandler: (
    roomToken: string,
    localIdentity: string,
    participantId: string,
    approved: boolean
  ) => Promise<void>;
}

export default function useRoom(localTracks: LocalTrack[], onError: Callback, options?: ConnectOptions) {
  const dispatch = useDispatch();
  const commClientRef = useRef<CommClientExtenders>();
  const [room, setRoom] = useState<Room | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const optionsRef = useRef(options);
  const [hasRemoteParticipants, setHasRemoteParticipants] = useState(false);
  const participantRef = useRef<boolean>(hasRemoteParticipants);
  participantRef.current = hasRemoteParticipants;
  const [imService, setImService] = useState<string | null>(null);

  useEffect(() => {
    // This allows the connect function to always access the most recent version of the options object. This allows us to
    // reliably use the connect function at any time.
    optionsRef.current = options;
  }, [options]);

  const handleJoinRequest = useCallback(
    async (incomingParticipant: IncomingParticipant, approved: boolean) => {
      if (!commClientRef.current || !room || !imService || _isEmpty(incomingParticipant)) return;
      const {
        localParticipant: { identity: localIdentity },
      } = room;
      const { roomToken, participantId } = incomingParticipant;

      await commClientRef.current.joinRequestHandler(roomToken, localIdentity, participantId, approved);
    },
    [imService, room]
  );

  const connect = useCallback(
    (token, name, attributes) => {
      setIsConnecting(true);
      return Video.connect(token, { ...optionsRef.current, tracks: localTracks }).then(
        async newRoom => {
          setRoom(newRoom);
          const disconnect = () => newRoom.disconnect();

          // This app can add up to 13 'participantDisconnected' listeners to the room object, which can trigger
          // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
          newRoom.setMaxListeners(15);

          newRoom.once('disconnected', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(null));
            window.removeEventListener('beforeunload', disconnect);

            if (isMobile) {
              window.removeEventListener('pagehide', disconnect);
            }
            window.close();
          });

          // @ts-ignore
          window.twilioRoom = newRoom;

          (newRoom.localParticipant as any).name = name;

          newRoom.localParticipant.videoTracks.forEach(publication =>
            // All video tracks are published with 'low' priority because the video track
            // that is displayed in the 'MainParticipant' component will have it's priority
            publication.setPriority('low')
          );

          setIsConnecting(false);

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', disconnect);
          }

          setTimeout(() => {
            if (!participantRef.current) {
              window.close();
            }
          }, 300000);

          const localParticipantId = newRoom.localParticipant.identity;
          const roomToken = newRoom.name;
          const { rongcloudToken, shouldConnectIM, imService: im } = attributes;
          if (shouldConnectIM) {
            setImService(im);
            if (im === 'RONGCLOUD' && !!rongcloudToken) {
              const commClient = new RongCloudCommClient(roomToken, localParticipantId, dispatch, rongcloudToken);
              commClientRef.current = commClient;
            } else if (im === 'TWILIO') {
              const { boothId = '', domain = '' } = queryString.parse(document.location.search) as URLParameterType;
              const commClient = new TwilioCommClient(roomToken, localParticipantId, dispatch, boothId, domain);
              await commClient.init();
              commClientRef.current = commClient;
            }
          }
        },
        error => {
          window.close();
          onError(error);
          setIsConnecting(false);
        }
      );
    },
    [localTracks, onError, dispatch]
  );

  return {
    room,
    isConnecting,
    connect,
    setHasRemoteParticipants,
    handleJoinRequest,
    imService,
  };
}
