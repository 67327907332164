import { request } from 'graphql-request';

const respondJoinGroupVideoCallRoomMutation = `
  mutation groupVideoCallRoomJoinResponse($roomToken: String!, $participantId: String!, $inviterId: String!, $isApproved: Boolean!) {
    groupVideoCallRoomJoinResponse(input: {
      roomToken: $roomToken,
      inviterId: $inviterId,
      isApproved: $isApproved,
      participantId: $participantId,
    }) {
      success
      errors {
        path
        message
        type
        details {
          key
          value
        }
      }
    }
  }
`;

function handleJoinRoomRequest(roomToken: string, inviterId: string, participantId: string, isApproved: boolean) {
  return request(
    process.env.REACT_APP_API_URL || 'http://localhost:3000/graphql',
    respondJoinGroupVideoCallRoomMutation,
    {
      roomToken,
      inviterId,
      participantId,
      isApproved,
    }
  ).then(() => {});
}

export default handleJoinRoomRequest;
