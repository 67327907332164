import { useEffect, useState } from 'react';
import { RemoteParticipant as TwilioRemoteParticipant } from 'twilio-video';
import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import useVideoContext from '../useVideoContext/useVideoContext';
import queryVideoCallRoom, { queryVideoCallRoomResultType } from '../../network/queryVideoCallRoom';
import { VideoCallUser } from 'types';

interface RemoteParticipant extends TwilioRemoteParticipant {
  name?: string;
}

export default function useParticipants() {
  const { room, videoCallUsers, setHasRemoteParticipants, updateVideoCallUsers } = useVideoContext();
  const dominantSpeaker = useDominantSpeaker();
  const [participants, setParticipants] = useState(Array.from(room?.participants.values() ?? []));

  // When the dominant speaker changes, they are moved to the front of the participants array.
  // This means that the most recent dominant speakers will always be near the top of the
  // ParticipantStrip component.
  useEffect(() => {
    if (dominantSpeaker) {
      setParticipants(prevParticipants => [
        dominantSpeaker,
        ...prevParticipants.filter(participant => participant !== dominantSpeaker),
      ]);
    }
  }, [dominantSpeaker]);

  useEffect(() => {
    if (room) {
      const roomParticipants = room.participants;
      roomParticipants.forEach((participant: RemoteParticipant) => {
        const { name } = (videoCallUsers.find(({ uuid }) => uuid === participant.identity) || {}) as VideoCallUser;
        participant.name = name;
      });
      setHasRemoteParticipants(roomParticipants.size > 0);
    }
  }, []);

  useEffect(() => {
    if (room) {
      const participantConnected = async (participant: RemoteParticipant) => {
        const {
          videoCallRoomByToken: {
            participants: { nodes },
          },
        }: queryVideoCallRoomResultType = await queryVideoCallRoom(room.name);
        updateVideoCallUsers(nodes);

        const { name } = (nodes.find(({ uuid }) => uuid === participant.identity) || {}) as VideoCallUser;
        participant.name = name;
        setHasRemoteParticipants(true);
        setParticipants(prevParticipants => [...prevParticipants, participant]);
      };
      const participantDisconnected = (participant: RemoteParticipant) => {
        setParticipants(prevParticipants => {
          const remainingParticipants = prevParticipants.filter(p => p !== participant);
          if (!remainingParticipants.length) {
            room.disconnect();
            window.close();
          }
          return remainingParticipants;
        });
      };
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      return () => {
        room.off('participantConnected', participantConnected);
        room.off('participantDisconnected', participantDisconnected);
      };
    }
  }, [room]);

  return participants;
}
