import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, Grid, Button, Theme } from '@material-ui/core';
import { Steps } from '../PreJoinScreens';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    textAlign: 'center',
    marginBottom: '32px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
}));

interface CancelPageProps {
  setStep: (step: Steps) => void;
}

export default function CancelPage({ setStep }: CancelPageProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleCancel = () => {
    window.close();
  };

  return (
    <>
      <Typography variant="h5" className={classes.header}>
        {t('twilio.media_setting.cancel_page.header')}
      </Typography>
      <Grid container justify="center">
        <Grid item md={5} sm={12} xs={12}>
          <Grid container direction="column" justify="space-between" style={{ height: '100%' }}>
            <div className={classes.buttons}>
              <Button variant="outlined" color="primary" onClick={handleCancel}>
                {t('twilio.media_setting.cancel_page.yes')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                data-cy-join-now
                onClick={() => setStep(Steps.deviceSelectionStep)}
              >
                {t('twilio.media_setting.cancel_page.no')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
