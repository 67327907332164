import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { IncomingParticipant } from 'types';

export const slice = createSlice({
  name: 'reducer',
  initialState: {
    localParticipantId: null as string | null,
    incomingParticipants: [] as Array<IncomingParticipant>,
  },
  reducers: {
    setLocalParticipantId(state, action: PA<string>) {
      state.localParticipantId = action.payload;
    },
    addParticipant(state, action: PA<IncomingParticipant>) {
      state.incomingParticipants.push(action.payload);
    },
    removeParticipant(state, action: PA<string[]>) {
      const removeIds = action.payload;
      state.incomingParticipants = state.incomingParticipants.filter(
        participant => !removeIds.includes(participant.participantId)
      );
    },
  },
});

export const { setLocalParticipantId, addParticipant, removeParticipant } = slice.actions;

export default slice.reducer;
