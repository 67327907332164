import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, Grid, Button, Theme, Hidden } from '@material-ui/core';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { ParticipantInfo, URLParameterType } from 'types';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ roomName, setStep }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { isFetching, setRoomType, getToken } = useAppState();
  const { isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const { connect, updateVideoCallUsers } = useVideoContext();
  const [participantInfo, setParticipantInfo] = useState<ParticipantInfo>({} as ParticipantInfo);
  const { t } = useTranslation();

  useEffect(() => {
    const { roomToken, participantId } = queryString.parse(document.location.search) as URLParameterType;
    if (roomToken && participantId) {
      getToken(participantId, roomToken).then(result => {
        const {
          success,
          roomMember,
          videoCallRoom,
          participant: { rongcloudToken },
        } = result;
        if (success) {
          const {
            participants: { nodes: rongcloudUsers },
            imService,
            kind,
          } = videoCallRoom;
          setRoomType(rongcloudUsers.length > 2 ? 'group' : 'peer-to-peer');
          const { twilioToken, isInviter } = roomMember;
          const localparticipant = rongcloudUsers.find(({ uuid }) => uuid === participantId);
          updateVideoCallUsers(rongcloudUsers);
          setParticipantInfo({
            name: localparticipant?.name || participantId,
            twilioToken,
            rongcloudToken: isInviter ? rongcloudToken : null,
            isInviter,
            imService,
            kind,
          });
        } else {
          window.close();
        }
      });
    }
  }, []);

  const handleJoin = () => {
    if (participantInfo) {
      const { twilioToken, name, rongcloudToken, imService, kind, isInviter } = participantInfo;
      connect(twilioToken, name, {
        rongcloudToken,
        shouldConnectIM: kind === 'GROUP' && isInviter,
        imService,
      });
    }
  };

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        {t('twilio.media_setting.header')}
      </Typography>

      <Grid container justify="center">
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={participantInfo.name} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
              <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
            </Hidden>
            <SettingsMenu mobileButtonClass={classes.mobileButton} />
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid container direction="column" justify="space-between" style={{ height: '100%' }}>
            <div>
              <Hidden smDown>
                <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
                <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
              </Hidden>
            </div>
            <div className={classes.joinButtons}>
              <Button variant="outlined" color="primary" onClick={() => setStep(Steps.cancelStep)}>
                {t('twilio.media_setting.cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                data-cy-join-now
                onClick={handleJoin}
                disabled={disableButtons}
              >
                {t('twilio.media_setting.join_now')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
