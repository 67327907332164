import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { styled } from '@material-ui/core/styles';
import { useTranslation, Trans } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import acceptCallIcon from './accept_call.png';
import hangUpCallIcon from './hangup_call.png';
import { ModalPropType } from './index';

const Container = styled('div')({
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  display: 'flex',
  height: '100%',
});

const ImgButton = styled('img')({
  cursor: 'pointer',
});

const CallingStatusContainer = styled('div')({
  textAlign: 'center',
  color: '#FFF',
});

const FlexContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  color: '#FFF',
});

const ButtonContainer = styled('div')({
  margin: '0 100px',
  whiteSpace: 'pre',
});

const MobileModal = ({ imMessageInfo, approveJoinRequest, rejectJoinRequest }: ModalPropType) => {
  const { t } = useTranslation();

  return (
    <Backdrop style={{ zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, 0.8)' }} open={!isEmpty(imMessageInfo)}>
      <Container>
        <CallingStatusContainer>
          <Trans
            i18nKey="call.join_in_mobile"
            values={{ name: imMessageInfo.name }}
            // eslint-disable-next-line jsx-a11y/heading-has-content
            components={{ b: <h2 /> }}
            defaults="<b>%{name}</b><b>want to join the video call</b>"
          />
        </CallingStatusContainer>
        <FlexContainer>
          <ButtonContainer role="button">
            <ImgButton src={hangUpCallIcon} alt="Hang Up" onClick={rejectJoinRequest} />
            <p>{t('call.hang_up', 'Hang Up')}</p>
          </ButtonContainer>
          <ButtonContainer role="button">
            <ImgButton src={acceptCallIcon} alt="Accept" onClick={approveJoinRequest} />
            <p>{t('call.approve', 'Approve')}</p>
          </ButtonContainer>
        </FlexContainer>
      </Container>
    </Backdrop>
  );
};

export default MobileModal;
