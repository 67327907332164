import React from 'react';
import { styled } from '@material-ui/core/styles';
import EventXtraBranding from './EventXtraBranding';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Footer = () => (
  <Container>
    <EventXtraBranding />
  </Container>
);

export default Footer;
