import { request } from 'graphql-request';

const videoCallTokenCreateMutation = `
  mutation VideoCallTokenCreate($participantId: String!, $roomToken: String!) {
    videoCallTokenCreate(input: {
      participantId: $participantId,
      roomToken: $roomToken
    }) {
      success
      errors {
        message
      }
      roomMember {
        twilioToken
        isInviter
      }
      videoCallRoom {
        participants {
          nodes {
            name
            uuid
          }
        }
        imService
        kind
      }
      participant {
        rongcloudToken
      }
    }
  }
`;

export interface createVideoCallTokenResultType {
  success: boolean;
  errors: {
    message: string;
  };
  roomMember: {
    twilioToken: string;
    isInviter: boolean;
  };
  videoCallRoom: {
    participants: {
      nodes: [
        {
          name: string;
          uuid: string;
        }
      ];
    };
    imService: string;
    kind: string;
  };
  participant: {
    rongcloudToken: string;
  };
}

function createVideoCallToken(participantId: String, roomToken: String) {
  return request(process.env.REACT_APP_API_URL || 'http://localhost:3000/graphql', videoCallTokenCreateMutation, {
    participantId,
    roomToken,
  }).then(videoCallTokenCreate => videoCallTokenCreate);
}

export default createVideoCallToken;
