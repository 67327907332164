import CommunicationClient from './CommunicationClient';
import { IncomingParticipant } from 'types';
import { useDispatch } from 'react-redux';
import joinRoomRequestApiCall from 'network/handleJoinRoomRequest';

/// <reference path="./RongIMLib-2.5.7.d.ts" />
const RongIMClient = RongIMLib.RongIMClient;
type Dispatch = ReturnType<typeof useDispatch>;

interface RongCloudMessage {
  messageType?: any;
  content: any;
  senderUserId: string;
  targetId: string;
  objectName: string;
  sentTime: number;
}

interface RongCloudMessageInfo {
  content: any;
  name: any;
  participantId: any;
  objectName: string;
  senderUserId: string;
  sentTime: number;
}

export class RongCloudCommClient extends CommunicationClient {
  status: RongIMLib.ConnectionStatus = RongIMLib.ConnectionStatus.CONNECTING;

  constructor(roomToken: string, localParticipantId: string, dispatch: Dispatch, rongCloudToken: string) {
    super(roomToken, localParticipantId, dispatch);
    this.init();
    this.connect(rongCloudToken);
  }

  init() {
    RongIMClient.init(process.env.REACT_APP_RONGCLOUD_APP_KEY || '');
    RongIMClient.setConnectionStatusListener({
      onChanged: (status: RongIMLib.ConnectionStatus) => {
        this.status = status;
      },
    });
    RongIMClient.setOnReceiveMessageListener({
      onReceived: (message: RongCloudMessage) => {
        const { content, name, participantId, objectName, senderUserId } = this.extractMessageInfo(message);
        if (
          content !== this.roomToken ||
          this.status !== RongIMLib.ConnectionStatus.CONNECTED ||
          this.localParticipantId === senderUserId
        )
          return;

        switch (objectName) {
          case 's:video:join':
            const incomingParticipant: IncomingParticipant = {
              name: name || senderUserId,
              roomToken: content,
              participantId: senderUserId,
            };
            this.addIncomingParticipant(incomingParticipant);
            return;
          case 's:video:approve':
          case 's:video:hangup':
            this.removeIncomingParticipant(participantId, senderUserId);
            return;
        }
      },
    });
  }

  connect(token: string) {
    RongIMClient.connect(token, {
      onSuccess: () => console.log('Connected to RongCloud'),
      onError: error => console.error(error),
      onTokenIncorrect: () => console.log('Incorrect Token'),
    });
  }

  async joinRequestHandler(roomToken: string, localIdentity: string, participantId: string, approved: boolean) {
    await joinRoomRequestApiCall(roomToken, localIdentity, participantId, approved);
    this.removeIncomingParticipant(participantId);
  }

  extractMessageInfo(message: RongCloudMessage): RongCloudMessageInfo {
    const {
      content: {
        message: {
          content: {
            content,
            user: { name },
            participantId,
          },
        },
      },
      objectName,
      senderUserId,
      sentTime,
    } = message;
    return {
      content,
      name,
      participantId,
      objectName,
      senderUserId,
      sentTime,
    };
  }
}
