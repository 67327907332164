import { Client as ConversationsClient } from '@twilio/conversations';
import { Conversation } from '@twilio/conversations/lib/conversation';
import _last from 'lodash/last';
import { useDispatch } from 'react-redux';
import { ConversationAttributes, IncomingParticipant, VideoCallMessageType, VideoMessage } from 'types';
import CommunicationClient from './CommunicationClient';

type Dispatch = ReturnType<typeof useDispatch>;

export const getTwilioJwt = async (participantId: string, domain: string): Promise<string | null> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_CHAT_API_URL}/token/${participantId}/${domain}`);
    const responseJson = await response.json();
    if (responseJson?.success) return responseJson.result;
    throw new Error('Failed to GET Twilio JWT Token');
  } catch (e) {
    console.error(e.message);
    return null;
  }
};

class TwilioCommClient extends CommunicationClient {
  client: ConversationsClient | null = null;
  conversation: Conversation | null = null;
  boothId: string | null = null;
  domain: string | null = null;

  constructor(roomToken: string, localParticipantId: string, dispatch: Dispatch, boothId: string, domain: string) {
    super(roomToken, localParticipantId, dispatch);
    this.boothId = boothId;
    this.domain = domain;
  }

  async init() {
    const jwtToken = await getTwilioJwt(this.localParticipantId, this.domain!);
    if (!jwtToken) return;

    this.client = await ConversationsClient.create(jwtToken);
    this.client.on('conversationUpdated', this.conversationUpdatedHandler);
    this.conversation = await this.client.getConversationByUniqueName(this.boothId!);
  }

  conversationUpdatedHandler = ({
    conversation,
    updateReasons,
  }: {
    conversation: Conversation;
    updateReasons: Conversation.UpdateReason;
  }) => {
    if (updateReasons.includes('attributes') && conversation.uniqueName === this.boothId) {
      this.conversation = conversation;
      this.onAttributeUpdatedHandler(conversation);
    }
  };

  onAttributeUpdatedHandler(conversation: Conversation) {
    const attributes = conversation.attributes as ConversationAttributes;
    if (attributes?.video?.length) {
      const latestMessage = _last(attributes.video)!;
      if (latestMessage.content.roomToken !== this.roomToken || this.localParticipantId === latestMessage.author)
        return;

      const videoCallActionHandler: Record<VideoCallMessageType, () => Promise<void>> = {
        [VideoCallMessageType.VideoCallInvite]: async () => {},
        [VideoCallMessageType.VideoCallAccept]: async () => {},
        [VideoCallMessageType.VideoCallHangup]: () => this.onReceiveHangup(conversation, latestMessage),
        [VideoCallMessageType.VideoCallNotSupport]: async () => {},
        [VideoCallMessageType.VideoCallJoinApprove]: async () => {},
        [VideoCallMessageType.VideoCallJoinRequest]: () => this.onReceiveJoinRequest(conversation, latestMessage),
        [VideoCallMessageType.VideoCallSummary]: async () => {},
      };

      videoCallActionHandler[latestMessage.type]();
    }
  }

  async onReceiveJoinRequest(conversation: Conversation, latestMessage: VideoMessage) {
    const { roomToken, targetId, name } = latestMessage.content;
    const participant: IncomingParticipant = {
      name: name as string,
      roomToken,
      participantId: targetId,
    };
    this.addIncomingParticipant(participant);
  }

  async onReceiveHangup(conversation: Conversation, latestMessage: VideoMessage) {
    const { targetId } = latestMessage.content;
    this.removeIncomingParticipant(targetId, latestMessage.author);
  }

  async pushConversationAttributes(conversation: Conversation, message: VideoMessage) {
    const attributes = conversation.attributes as ConversationAttributes;
    const newVideoAttributes = [...(attributes?.video || []), message];
    await conversation.updateAttributes({
      ...attributes,
      video: newVideoAttributes,
    } as ConversationAttributes);
  }

  async joinRequestHandler(roomToken: string, localIdentity: string, participantId: string, approved: boolean) {
    const message: VideoMessage = {
      type: approved ? VideoCallMessageType.VideoCallJoinApprove : VideoCallMessageType.VideoCallHangup,
      content: {
        targetId: participantId,
        roomToken,
        name: null,
      },
      dateSent: new Date(),
      author: localIdentity,
    };
    await this.pushConversationAttributes(this.conversation!, message);
    this.removeIncomingParticipant(participantId);
  }
}

export default TwilioCommClient;
