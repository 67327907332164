import { request } from 'graphql-request';

const queryVideoCallRoomMutation = `
  query videoCallRoomByToken($roomToken: String!) {
    videoCallRoomByToken(token: $roomToken) {
      participants {
        nodes {
          name
          uuid
        }
      }
    }
  }
`;

export interface queryVideoCallRoomResultType {
  videoCallRoomByToken: {
    participants: {
      nodes: [
        {
          name: string;
          uuid: string;
        }
      ];
    };
  };
}

function queryVideoCallRoom(roomToken: String) {
  return request(process.env.REACT_APP_API_URL || 'http://localhost:3000/graphql', queryVideoCallRoomMutation, {
    roomToken,
  }).then(data => data);
}

export default queryVideoCallRoom;
