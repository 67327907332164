import React from 'react';
import { styled } from '@material-ui/core/styles';

import EventxLogo from 'images/eventx-logo.png';

const RootContainer = styled('div')({
  height: '50px',
  width: '100px',
  borderRadius: '4px',
  position: 'relative',
});

const StyledBackdrop = styled('div')({
  height: '100%',
  width: '100%',
  opacity: '0.4',
  position: 'absolute',
  borderRadius: '4px',
});

const PoweredByContainer = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
});

const StyledAnchor = styled('a')({
  flex: '0 0 auto',
  marginTop: '8px',
  lineHeight: '50px',
  fontFamily: 'Lato, "sans-serif"',
});

const StyledImg = styled('img')({
  maxWidth: '70px',
  maxHeight: '20px',
  marginBottom: '3px',
});

const EventXtraBranding = () => (
  <RootContainer>
    <StyledBackdrop />
    <StyledAnchor
      href="https://eventx.io/virtual-exhibition-v"
      target="_blank"
      rel="noopener noreferrer"
      title="Powered by EventX"
    >
      <PoweredByContainer>
        <StyledImg src={EventxLogo} />
      </PoweredByContainer>
    </StyledAnchor>
  </RootContainer>
);

export default EventXtraBranding;
