import { useState, useCallback } from 'react';
import { VideoCallUser } from 'types';

export default function useVideoCallUsers() {
  const [videoCallUsers, setVideoCallUsers] = useState<VideoCallUser[]>([]);

  const updateVideoCallUsers = useCallback(users => {
    setVideoCallUsers(users);
  }, []);

  return { videoCallUsers, updateVideoCallUsers };
}
