import en from './common/en.json';
import idID from './common/id-ID.json';
import zhHans from './common/zh-Hans.json';
import zhHant from './common/zh-Hant.json';
import ja from './common/ja.json';
import ko from './common/ko.json';
import es from './common/es.json';
import fr from './common/fr.json';
import vi from './common/vi.json';

export const resources = {
  en: { translation: en },
  'id-ID': { translation: idID },
  'zh-Hans': { translation: zhHans },
  'zh-Hant': { translation: zhHant },
  ja: { translation: ja },
  ko: { translation: ko },
  es: { translation: es },
  fr: { translation: fr },
  vi: { translation: vi },
};
