import React from 'react';
import Video from 'twilio-video';
import { Container, Typography, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SupportedBrowserImg from './supported_browsers.png';

const useStyles = makeStyles({
  container: {
    marginTop: '2.5em',
  },
  paper: {
    padding: '1em',
  },
  img: {
    width: '100%',
    margin: '20px 0',
  },
  heading: {
    marginBottom: '0.4em',
  },
});

export default function UnsupportedBrowserWarning({ children }: { children: React.ReactElement }) {
  const classes = useStyles();

  if (!Video.isSupported) {
    return (
      <Container>
        <Grid container justify="center" className={classes.container}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Typography variant="h4" className={classes.heading}>
                Browser or context not supported
              </Typography>
              <Typography>
                Please refer to the supported browsers below, based on the device you're using, open the event website
                in one of the supportd browsers.
                <br />
                <img src={SupportedBrowserImg} alt="browsers" className={classes.img} />
                <br />
                *Chrome and Firefox for IOS do not have access to WebRTC APIs, unlike Safari for IOS.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return children;
}
