import React, { SVGProps } from 'react';

export default function TwilioLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="300.000000pt"
      height="59.000000pt"
      viewBox="0 0 300.000000 59.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0.000000,59.000000) scale(0.100000,-0.100000)" fill="#FFF" stroke="none">
        <path d="M134 490 c-47 -19 -54 -45 -54 -197 0 -136 1 -143 24 -170 l24 -28 179 -3 178 -3 0 25 0 26 -165 0 c-91 0 -171 4 -178 8 -9 7 -12 44 -10 153 l3 144 171 3 172 2 4 25 5 25 -166 -1 c-91 0 -176 -4 -187 -9z" />
        <path
          d="M510 477 c0 -19 6 -26 23 -29 17 -2 38 -33 94 -138 115 -216 119 -222 150 -218 29 3 30 5 164 253 l54 100 238 3 237 2 0 25 0 25 -232 0 c-166 0 -240 -4 -258 -13 -17 -8 -51 -62 -111 -175 -47 -89 -88 -165 -91 -168 -4 -3 -45 69 -92 159 -48 91 -92 170 -100 176 -7 6 -27 14 -44 17 -29 6 -32 4 -32
        -19z"
        />
        <path
          d="M1547 482 c-15 -16 -17 -46 -17 -205 0 -180 1 -187 20 -187 19 0 20 7 20 180 0 104 4 180 9 180 5 0 73 -78 150 -174 159 -197 171 -206 211 -166 18 18 20 33 20 168 0 102 4 152 12 160 9 9 74 12 230 12 l218 0 0 25 0 25 -215 0 c-227 0 -261 -6 -281 -47 -5 -10 -11 -87 -14 -171 l-5 -154 -126 159
        c-156 194 -173 213 -197 213 -10 0 -26 -8 -35 -18z"
        />
        <path d="M2200 250 c0 -159 0 -160 23 -160 22 0 22 1 22 160 0 159 0 160 -22 160 -23 0 -23 -1 -23 -160z" />
        <path d="M170 295 l0 -25 140 0 140 0 0 25 0 25 -140 0 -140 0 0 -25z" />
        <path d="M1082 308 c-16 -16 -16 -190 0 -206 8 -8 67 -12 200 -12 l188 0 0 25 0 25 -180 0 -180 0 0 65 0 65 165 0 165 0 0 25 0 25 -173 0 c-121 0 -177 -4 -185 -12z" />
        <path d="M2450 475 c0 -22 4 -25 34 -25 28 0 44 -11 109 -77 l76 -78 -77 -77 c-64 -66 -82 -78 -109 -78 -29 0 -33 -3 -33 -25 0 -22 4 -25 38 -25 20 1 46 6 57 13 25 15 185 181 185 192 0 11 -160 177 -185 192 -11 7 -37 12 -57 13 -34 0 -38 -3 -38 -25z" />
        <path d="M2789 423 c-78 -75 -79 -77 -61 -96 18 -18 20 -17 87 52 58 60 73 71 102 71 29 0 33 3 33 25 0 23 -4 25 -41 25 -38 0 -48 -6 -120 -77z" />
        <path d="M2727 262 c-16 -18 -13 -23 62 -95 72 -71 82 -77 120 -77 37 0 41 2 41 25 0 22 -4 25 -33 25 -29 0 -44 11 -101 70 -37 39 -69 70 -70 70 -2 0 -10 -8 -19 -18z" />
      </g>
    </svg>
  );
}
