import React, { useCallback } from 'react';
import DesktopModal from './DesktopModal';
import MobileModal from './MobileModal';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { isMobile } from '../../utils';
import { useSelector } from 'react-redux';
import { selectFirstIncomingParticipant } from 'redux/selectors';
import { IncomingParticipant } from 'types';

export interface ModalPropType {
  imMessageInfo: IncomingParticipant;
  approveJoinRequest: () => void;
  rejectJoinRequest: () => void;
}

const RequestModal = () => {
  const { handleJoinRequest } = useVideoContext();
  const incomingParticipant = useSelector(selectFirstIncomingParticipant);

  const approveJoinRequest = useCallback(() => {
    handleJoinRequest(incomingParticipant, true);
  }, [incomingParticipant, handleJoinRequest]);

  const rejectJoinRequest = useCallback(() => {
    handleJoinRequest(incomingParticipant, false);
  }, [incomingParticipant, handleJoinRequest]);

  return (
    <>
      {isMobile ? (
        <MobileModal
          imMessageInfo={incomingParticipant}
          approveJoinRequest={approveJoinRequest}
          rejectJoinRequest={rejectJoinRequest}
        />
      ) : (
        <DesktopModal
          imMessageInfo={incomingParticipant}
          approveJoinRequest={approveJoinRequest}
          rejectJoinRequest={rejectJoinRequest}
        />
      )}
    </>
  );
};

export default RequestModal;
